import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'
import sgfParse from '@/global/js/sgfParse'

class gameReivew extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'gameReview'
        let name = 'gameReview'
        opt = {localKey, name}
        super(opt)
        this._sgfParse = new sgfParse()
    }

    async gameMeta(opt){
        opt = opt || {}
        let {username, gameId} = opt
        username = username || this._appData.getItem('username', 'app')
        if(!gameId) return
        opt = {
            ...opt,
            username
        }
        let options = {
            ...opt,
            method: 'get',
            data: {
                id: gameId,
                username
            },
        }
        let res = await this.requestAction('gameMeta', options)
        return res
    }

    async gameSgf(opt){
        opt = opt || {}
        let {username, gameId} = opt
        username = username || this._appData.getItem('username', 'app')
        if(!gameId) return
        opt = {
            ...opt,
            username
        }
        let options = {
            ...opt,
            method: 'get',
            data: {
                id: gameId,
            },
        }
        let res = await this.requestAction('gameSgf', options)
        if(res){
            let movesData = await this._sgfParse.parseSgfForReport(res.sgf)
            res = {
                ...res,
                ...movesData
            }
        }
        return res
    }

    async reviewDataCreate(opt){
        opt = opt || {}
        let {gameReviewId = this.PARAMS.gameReviewId, gameId, moves = ''} = opt
        if(!gameId) return
        let options = {
            ...opt,
            method: 'post',
            data: {
                game_id: gameId,
                report_id: gameReviewId,
                moves
            },
        }
        let res = await this.requestAction('reviewDataCreate', options)
        return res
    }
    async reviewData(opt){
        opt = opt || {}
        let {username, gameId, start_move_num = 0, end_move_num} = opt
        username = username || this._appData.getItem('username', 'app')
        if(!gameId) return
        opt = {
            ...opt,
            username
        }
        let data = {
            id: gameId,
            start_move_num
        }
        if(end_move_num !== undefined) data.end_move_num = end_move_num
        let options = {
            ...opt,
            method: 'get',
            data,
        }
        let res = await this.requestAction('reviewData', options)
        return res
    }
}
export default singleProxy(gameReivew)
