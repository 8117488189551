<!--  -->
<template>
    <div class='com-page-game-review-move-switch cursor-pointer no-selected' @click="change" :class="{'active': state, 'disabled-img': disabled}">
        <div class="switch-item a position-vertical-center-margin flex-center">
            <span class="text-mini-bc bold" v-if="state">手数</span>
            <span class="text-mini-bc bold" v-else>123</span>
        </div>
        <div class="switch-item b position-vertical-center-margin flex-align-center">
            <span class="text-mini-cc bold a" v-if="state">123</span>
            <span class="text-mini-cc bold b" v-else>手数</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            disabled: Boolean
        },
        data() {
            return {
                state: false
            };
        },
        methods: {
            change(){
                if(!this.disabled) this.state = !this.state
                this.$emit('change', this.state)
            }
        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-page-game-review-move-switch{
        width: 34px;
        position: relative;
        height: 10px;
        .switch-item.a{
            transition: all .2s;
            z-index: 1;
            left: 0px;
            width: 19px;
            height: 100%;
            color: #8476B5;
            .background-img-max(url('@{assetsUrlV2_0}/gameReview/g5.png'));
        }
        .switch-item.b{
            transition: all .2s;
            left: 12px;
            width: 22px;
            height: 8px;
            color: #8C5532;
            justify-content: flex-end;
            padding-right: 3px;
            .background-img-max(url('@{assetsUrlV2_0}/gameReview/g6.png'));
        }
        .text-mini-cc.a{
            transform: scale(0.4) translateX(-60%);
        }
    }
    .com-page-game-review-move-switch.active{
        .switch-item.a{
            left: 15px;
        }
        .switch-item.b{
            left: 0px;
            padding-left: 3px;
            padding-right: 0px;
            justify-content: flex-start;
        }
    }
</style>