<!--  -->
<template>
    <div class='com-review-terrible-great-move'>
        <div class="tg-slider flex-align-center">
            <div class="great-move-box" :style="terGreStyle">
                <div class="gre-item flex-center" v-for="v in terrible_great_moves" :key="v.moveNum"
                :class="{'active': v.greatMove, 'selected': position == v.moveNum}"
                :style="{width: `${100 / terrible_great_moves.length}%`}"
                >
                    <div class="gre-item-body cursor-pointer scale-button" v-if="v.greatMove" @click="change(v.moveNum)">
                        <!-- <div class="item-text flex-center">
                            <span class="text-mini-bc bold">第{{v.moveNum + 1}}手</span>
                        </div> -->
                        <div class="item-icon">
                            <div class="icon-i shadow"></div>
                            <div class="icon-i bcg"></div>
                            <div class="icon-i text flex-center">
                                <span class="text-mini-bc bold">第{{v.moveNumPre + 1}}手</span>
                            </div>
                            <div class="icon-i content position-align-center-margin"></div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <van-slider v-model="positionTem" @change="change" :min="startMoveNum" :max="step" />
            <div class="terrible-move-box" :style="terGreStyle">
                <div class="terr-item flex-center" v-for="v in terrible_great_moves" :key="v.moveNum"
                :class="{'active': v.terribleMove, 'selected': position == v.moveNum}"
                :style="{width: `${100 / terrible_great_moves.length}%`}"
                >
                    <div class="terr-item-body cursor-pointer scale-button" v-if="v.terribleMove" @click="change(v.moveNum)">
                        <div class="item-icon">
                            <div class="icon-i shadow"></div>
                            <div class="icon-i bcg"></div>
                            <div class="icon-i text flex-center">
                                <span class="text-mini-bc bold">第{{v.moveNumPre + 1}}手</span>
                            </div>
                            <div class="icon-i content position-align-center-margin"></div>
                        </div>
                        <!-- <div class="item-text flex-center">
                            <span class="text-mini-bc bold">第{{v.moveNum + 1}}手</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            position: Number,
            min: {
                default: 0,
                type: Number
            },
            step: Number,
            reviewData: {
                type: Array,
                default(){
                    return []
                }
            },
            userColor: Number,
            startMoveNum: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                positionTem: 0
            };  
        },
        methods: {
            change(val){
                this.$emit('change', val)
            },
            isTerribleMove(val){
                let {terribleMoveLevel: terr} = this.PARAMS
                let {data: {level}} = val
                if(level >= terr) return true
                return false
            },
            isgreatMove(val){
                let {greatMoveLevel: gre} = this.PARAMS
                let {data: {level, options}} = val
                if(level > gre) return false
                if(!options[1]) val.winrateDelta = 0.01
                else {
                    let winrate0 = 1 - options[0].winrate
                    let winrate1 = 1 - options[1].winrate
                    val.winrateDelta = winrate0 - winrate1
                }
                return val
            },
        },
        created() {

        },
        components: {
        },
        computed: {
            terGreStyle(){
                let size = (100 / this.terrible_great_moves.length / 2).toFixed(0)
                let width = `${100 + size * 2}%`
                let marginLeft = `-${size}%`
                let sty = {
                    width,
                    marginLeft
                }
                return sty
            },
            terriblePoint(){
                let {terrible} = this.terrible_great_moves_data
                return terrible
            },
            greatPoint(){
                let {great} = this.terrible_great_moves_data
                return great
            },
            terrible_great_moves_data(){
                let {reviewData: list, userColor, startMoveNum: num} = this
                list = this._common.deepCopy(list)
                let terrible = [], great = [], color = userColor == 1 ? 0 : 1
                for(let i in list){
                    list[i].index = i
                    let {moveNum} = list[i]
                    if(moveNum <= num) continue
                    if(moveNum % 2 != color) continue
                    if(this.isTerribleMove(list[i])) terrible.push({
                        ...list[i],
                        terribleMove: true
                    })
                    let greVal = this.isgreatMove(list[i])
                    if(greVal) great.push(greVal)
                }
                return {great, terrible}
            },
            terrible_great_moves(){
                let {reviewData: list, userColor, startMoveNum: num} = this
                list = this._common.deepCopy(list)
                let tem = this._common.deepCopy(list)
                // list = list.splice(num - 1, list.length - num)
                let {great, terrible} = this.terrible_great_moves_data
                let {terribleMoveMaxNum: terrNum, greatMoveMaxNum: greNum} = this.PARAMS
                if(terrible.length > terrNum){
                    let j = Math.ceil(terrible.length / 2)
                    if(j == terrible.length - 1) j--
                    let tem = [
                        terrible[0],
                        terrible[terrible.length - 1],
                        terrible[j],
                    ]
                    terrible = tem
                }
                if(great.length > greNum){
                    let j = Math.ceil(great.length / 2)
                    if(j == great.length - 1) j--
                    let tem = [
                        great[0],
                        great[great.length - 1],
                        great[j],
                    ]
                    great = tem
                }
                let rd = terrible.concat(great)
                for(let i in rd){
                    let {index, winrateDelta, terribleMove, moveNum} = rd[i]
                    if(!list[index]) continue
                    if(terribleMove) list[index].terribleMove = true
                    else list[index].greatMove = true
                    list[index].moveNumPre = moveNum - num
                }
                list = list.splice(num, list.length - num)
                return list
                
            },
            terribleMoves(){
                let {terribleMoveLevel: terr, terribleMoveMaxNum: num} = this.PARAMS
                let {reviewData: list, userColor} = this
                list = this._common.deepCopy(list)
                let rd = [], color = userColor == 1 ? 0 : 1
                for(let i in list){
                    let {data: {level, evaluate}, moveNum} = list[i]
                    list[i].index = i
                    if(moveNum % 2 != color) continue
                    if(level >= terr){
                        rd.push(list[i])
                    }
                }
                rd = this._common.sortData(rd, (v1, v2) => {
                    let {data: {evaluate: eva1}} = v1
                    let {data: {evaluate: eva2}} = v2
                    return Number(eva1) > Number(eva2)
                })
                rd = rd.splice(0, num)
                for(let i in rd){
                    // if(i >= num) break
                    let {index} = rd[i]
                    if(!list[index]) continue
                    list[index].terribleMove = true
                }
                return list
            }
        },
        watch: {
            position: {
                handler(val){
                    this.positionTem = val
                },
                deep: true,
                immediate: true
            },
            terrible_great_moves_data: {
                handler(val){
                    this.$emit('terribleGreatChange', val)
                },
                deep: true,
                immediate: true
            },
            terrible_great_moves: {
                handler(val){
                    this.$emit('pointCalculate', val)
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-review-terrible-great-move{
        position: relative;
        width: 100%;
        .tg-slider{
            width: 100%;
            height: 10px;
            padding: 0px 3px;
            .background-img-max(url('@{assetsUrlV2_0}/gameReview/g7.png'));
            .van-slider{
                height: 5px;
                background: #BABABA;
                border: 1px solid #969393;
            }
            .van-slider__bar{
                background: #5BB2FF;
                border: 1px solid #3D86DC;
            }
            .van-slider__button{
                width: 8px;
                height: 8px;
            }
        }
        .terr-item.active, .gre-item.active{
            z-index: 2;
        }
        .terr-item.selected, .gre-item.selected{
            z-index: 3;
        }
        .terrible-move-box{
            position: absolute;
            left: 0px;
            bottom: -35px;
            display: flex;
            width: 100%;
            padding: 0px 3px;
            .terr-item{
                height: 37px;
                position: relative;
                .terr-item-body{
                    position: absolute;
                    width: 27px;
                    height: 37px;
                }
                .item-icon{
                    position: relative;
                    width: 100%;
                    height: 30px;
                    .icon-i{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                    }
                    .bcg{
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g8_terrible.png'));
                    }
                    .shadow{
                        width: 30px;
                        height: 31px;
                        top: 2px;
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g8_terrible_shadow.png'));
                    }
                    .content{
                        top: 7px;
                        width: 14px;
                        height: 16px;
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g8_terrible_icon.png'));
                    }
                    .text{
                        width: 100%;
                        height: 8px;
                        color: #926744;
                        bottom: 0px;
                        top: auto;
                        left: 0px;
                    }
                }
                .item-text{
                    width: 100%;
                    height: 7px;
                    margin-top: 2px;
                    color: #926744;
                }
            }   
        }

        .great-move-box{
            position: absolute;
            left: 0px;
            top: -25px;
            display: flex;
            width: 100%;
            padding: 0px 3px;
            .gre-item{
                height: 27px;
                position: relative;
                .gre-item-body{
                    position: absolute;
                    width: 27px;
                    height: 37px;
                }
                .item-icon{
                    position: relative;
                    width: 100%;
                    height: 30px;
                    .icon-i{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0px;
                        top: 0px;
                    }
                    .bcg{
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g8_great.png'));
                    }
                    .shadow{
                        width: 30px;
                        height: 28px;
                        top: 0px;
                        left: 0px;
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g8_great_shadow.png'));
                    }
                    .content{
                        bottom: 7px;
                        top: auto;
                        width: 18px;
                        height: 16px;
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g8_great_icon.png'));
                    }
                    .text{
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 8px;
                        color: #926744;
                    }
                }
                .item-text{
                    width: 100%;
                    height: 7px;
                    margin-top: 2px;
                    color: #926744;
                }
            }
        }
    }
</style>