<!--  -->
<template>
    <div class='page-game-review-summary flex-just-center'>
        <component-bcg url="gameReview/bcg.png"></component-bcg>
        <div class="ex-bcg-box">
            <div class="stone-item black"></div>
            <div class="stone-item white"></div>
        </div>
        <div class="review-page-title flex-just-center">
            <div class="text-body">
                <span class="text-large bold">{{pageText['page title']}}</span>
            </div>
        </div>
        <div class="review-content flex-align-center">
            <div class="review-item board">
                <div class="board-body" :style="boardStyle" :class="{'visibility-hidden': !boardSize}">
                    <board ref="board" :boardSize="boardSize" @emitEvent="emitEvent" :minNum.sync="startMoveNum" ></board>
                    <div class="board-extend" v-if="$slots['board-extend']">
                        <slot name="board-extend"></slot>
                    </div>
                </div>
            </div>
            <div class="review-item options">
                <div class="opt-item g-info">
                    <div class="g-remove-box position-align-center-margin">
                        <div class="remove-shadow item"></div>
                        <div class="remove-text item">
                            <div class="text-it a position-align-center-margin flex-center">
                                <span class="text-normal bold">{{removeText}}</span>
                            </div>
                            <div class="text-it b flex-center">
                                <!-- <span class="text-large bold">{{userRemove}}</span> -->
                                <span class="text-large bold">{{blackRemove}}</span>
                            </div>
                            <div class="text-it c flex-center">
                                <!-- <span class="text-large bold">{{AIRemove}}</span> -->
                                <span class="text-large bold">{{whiteRemove}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-item content">
                        <div class="con-item left flex-just-start">
                            <div class="left-bcg bcg-item"></div>
                            <!-- <div class="stone" :class="{'black': userColor == 1, 'white': userColor == -1}"></div> -->
                            <div class="stone black"></div>
                            <div class="photo-box">
                                <!-- <user-photo></user-photo> -->
                                <!-- <black-photo :gameMeta="gameMeta"></black-photo> -->
                                <photo :gameMeta="gameMeta" color="black" :info="blackInfo" v-if="blackInfo && blackInfo.avatarHeadUrl"></photo>
                            </div>
                            <div class="name-board flex-center">
                                <!-- <span class="text-mini-c bold">{{leftInfo.nickname}}</span> -->
                                <span class="text-mini-c bold">{{blackInfo.nickname}}</span>
                            </div>
                        </div>
                        <div class="con-item right flex-just-end">
                            <div class="right-bcg bcg-item"></div>
                            <!-- <div class="stone" :class="{'white': userColor == 1, 'black': userColor == -1}"></div> -->
                            <div class="stone white"></div>
                            <div class="photo-box">
                                <!-- <AI-photo></AI-photo> -->
                                <photo :gameMeta="gameMeta" color="white" :info="whiteInfo" v-if="whiteInfo && whiteInfo.avatarHeadUrl"></photo>

                            </div>
                            <div class="name-board flex-center">
                                <!-- <span class="text-mini-c bold">{{rightInfo.nickname}}</span> -->
                                <span class="text-mini-c bold">{{whiteInfo.nickname}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="opt-item chat-box">
                    <div class="moves-switch-box">
                        <moveSwitch @click="changeLabel" @change="changeLabel" :disabled="changeLabelDisabled"></moveSwitch>
                    </div>
                    <div class="winrate-box">
                        <div class="win-item chat" :class="{'visibility-hidden': noneReviewData}">
                            <div class="winrate-head flex">
                                <span class="text-mini-bc bold">胜率</span>
                            </div>
                            <div class="winrate-content">
                                <winrateLine ref="winrateLine"
                                :reviewData.sync="reviewDataCul"
                                :position.sync="realPosition" 
                                :step.sync="realStep"
                                :userColor.sync="userColor"
                                :startMoveNum.sync="startMoveNum"
                                :appStyle.sync="appStyle"
                                :appSizeData.sync="appSizeData"
                                :terriblePoint.sync="terriblePoint"
                                :greatPoint.sync="greatPoint"
                                :pointCalculateData.sync="pointCalculateData"
                                @positionChange="positionChange($event, {type: 'winrate'})"
                                ></winrateLine>
                            </div>
                        </div>
                        <div class="win-item chat none-data flex-center" v-if="noneReviewData">
                            <div class="none-data-body">
                                <!-- <div class="none-data-text">
                                    <span class="text-mini">还没有生成复盘数据</span>
                                </div> -->
                                <div class="create-btn-box">
                                    <confirmPlay @click="createReview" class="scale-button">
                                        <span class="text-normal blod text-red-color i flex-center">{{"AI复盘"}}</span>
                                    </confirmPlay>
                                </div>
                            </div>
                            
                        </div>
                        <div class="win-item moves-number">
                            <span class="text-mini bold">第{{positionCul}}手</span>
                            <span class="text-mini bold">共{{stepCul}}手</span>
                        </div>
                    </div>
                    <div class="game-level-box flex-align-center">
                        <div class="level-box-item q">
                            <terGreMove ref="terGreMove" :position.sync="realPosition" :step.sync="realStep" @change="positionChangeSlider"
                            :reviewData.sync="reviewDataCul"
                            :userColor.sync="userColor"
                            :startMoveNum.sync="startMoveNum"
                            @terribleGreatChange="terribleGreatChange"
                            @pointCalculate="pointCalculate"
                            ></terGreMove>
                        </div>
                        <div class="level-box-item f">
                            <div class="fun-item cursor-pointer nowrap" :class="{'scale-button': !v.isDisabled, 'disabled-img': v.isDisabled}" v-for="v in funListCul" :key="v.fun" @click="funHandler(v)">
                                <div class="shadow"></div>
                                <div class="body flex-center">
                                    <span class="text-mini-b bold text">{{v.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="board-move-box flex-just-center">
                        <!-- :position.sync="realPosition" :step.sync="realStep" -->
                        <boardMove ref="boardMove" :position="position" :step="step" @change="positionChange" :min="startMoveNum"></boardMove>
                    </div>
                </div>
            </div>
        </div>
        <confirmPro ref="confirm">
            <div class="review-loading" slot="message">
                <div class="loading-title">
                    <span class="text-small bold">数据正在生成中</span><span class="text-small bold">({{finishedPer}}%)</span>
                </div>
                <div class="loading-slider">
                    <div class="press-content flex-align-center">
                        <div class="body" :style="finishedStyle"></div>
                    </div>
                </div>
            </div>
        </confirmPro>
    </div>
</template>

<script>
    import board from '@/components/board.vue'
    import apiClass from '@/API/page/gameReview'
    import hisApiClass from '@/API/page/history'
    import boardMove from '@/components/boardMove.vue'
    import moveSwitch from '../components/moveNumSwitch.vue'
    import terGreMove from '../components/terrible_great_move.vue'
    import winrateLine from '../components/winrateLine.vue'
    import photo from '../components/reviewPhoto.vue'
    import tryPlayConfig from '@/plugins/tool/tryPlay'
    import confirmPlayConfig from '@/plugins/tool/confirmPlay'

    export default {
        name: '',
        props: {
            boardStyle: Object,
            appStyle: Object,
            appSizeData: Object,
        },
        data() {
            return {
                ...tryPlayConfig.data.call(this),
                ...confirmPlayConfig.data.call(this),
                gamePlayCoor: {},
                api: new apiClass(),
                hisApi: new hisApiClass(),
                gameId: this.$route.params.gameId,
                gameMeta: {},
                gameSgfData: {},
                boardState: false,
                now: 1,
                position: 0,
                step: 0,
                removeStone: null,
                _board: null,
                reviewData: [],
                pageDestroy: false,
                finished: 0,
                greatPoint: [],
                terriblePoint: [],
                loadSgfDown: false,
                pointCalculateData: [],
                otherUserInfo: {},
                userList: {},
                funList: [
                    {name: '开始试下', fun: 'tryPlayCon', text: 'tryPlayText'},
                    {name: '停一手', fun: 'pass', disabled: 'passDisabled'},
                    {name: '确认落子', fun: 'confirmPlay', disabled: 'confirmPlayDisabled', display: 'confirmPlayDisplay'},
                ],
                confirmPlaySwitch: false
            };
        },
        methods: {
            ...tryPlayConfig.methods,
            ...confirmPlayConfig.methods,
            emitEvent(opt){
                this._common.emitEvent.call(this, opt)
            },
            update(opt){
                this._common.update.call(this, opt)
            },
            async gameMetaLoad(opt){
                let {gameId} = this
                opt = opt || {}
                opt = {
                    ...opt,
                    gameId
                }
                let res = await this.api.gameMeta(opt)
                if(!res) return
                console.log(this._common.deepCopy(res))
                this.gameMeta = res
            },
            async gameSgfLoad(){
                let {gameId, boardSize} = this
                let res = await this.api.gameSgf({gameId, boardSize})
                if(!res) return
                this.gameSgfData = res
            },
            async situationLoad(){
                let {situation} = this
                this._board.loadSgf(situation)
                this.loadSgfDown = true
            },
            boardLoaded(){
                this.boardState = true
                this._board = this.$refs['board']
            },
            positionChangeSlider(val){
                this.tryPlayCheck({type: 'slider'})
                if(val < this.startMoveNum) val = this.startMoveNum
                this.positionChange(val)
                // this.lineChatInit()
            },
            positionChange(val, opt){
                this.tryPlayCheck(opt)
                if(isNaN(Number(val))) val = this._board.board.position
                this._board.jumpToByposRange(val)
                this.lineChatInit()
            },
            tryPlayCheck(opt){
                let {type} = opt || {}
                let list = {
                    'winrate': true,
                    'slider': true
                }
                if(!list[type] || !this.tryPlaySelected) return
                this.tryPlayOff()
            },
            changeLabel(label){
                if(this.changeLabelDisabled) return
                if(this._common.isBool(label)){
                    label = label ? 1 : 0
                }else{
                    label = this.boardStoneLabel
                    label = label == 0 ? 1 : 0
                }
                this._board.changeLabel(label)
            },
            async reviewDataInit(){
                if(this.noneReviewData) return
                // if(this.noneReviewData) return this.reviewDataCreate()
                if(this.reviewDataCreating) return this.reviewDataStateWatchCon()
                if(this.reviewData.length >= (Number(this.step) + 1)) return
                await this.reviewDataSet()
            },
            async reviewDataSet(){
                let res = await this.reviewDataGet()
                if(!res) return
                this.reviewData = res.reportData
                this.lineChatInit()
            },
            async reviewDataCreate(){
                if(this.tryPlaySelected) this.tryPlayOff()
                let {gameId, situation: moves} = this
                moves = moves.join(',')
                let res = await this.api.reviewDataCreate({gameId, moves})
                if(res) return this.reviewDataStateWatchCon()
            },
            async reviewDataStateWatchCon(){
                this.createDataNoticeOpen()
                await this.reviewDataStateWatch()
                this.createDataNoticeClose()
            },
            async createDataNoticeOpen(){
                let res = await this.$refs['confirm'].open({
                    confirmType: 'c3',
                    buttons: [
                        {title: '退出', type: 'cancel', label: 0}
                    ]
                })
                res = res || {}
                if(res.label == 0) this.quit()
            },
            async createDataNoticeClose(){
                if(this.$refs['confirm']) this.$refs['confirm'].close()
            },
            async reviewDataStateWatch(p){
                if(this.pageDestroy) return
                p = p || 0
                if(p >= this.step) return
                // gameReviewWatchInterval
                let ts = (new Date()).getTime()
                let res = await this.reviewDataGet({start_move_num: p, noError: true, noLoad: true})
                if(res){
                    let {finished} = res
                    let {step} = this
                    p = this.reviewDataPush(res.reportData)
                    this.finished = finished
                    console.log(step)
                    console.log(p)
                    if(p >= Number(step)){
                        this.gameMetaLoad({noError: true, noLoad: true})
                        return this.reviewDataSet()
                    }
                }
                let delta = this.PARAMS.gameReviewWatchInterval - ((new Date()).getTime() - ts)
                await this._common.settimeout(delta)
                return this.reviewDataStateWatch(p)
            },
            getReviewObject(data){
                let rd = {}
                data = data || []
                for(let i in data){
                    let {moveNum} = data[i]
                    rd[moveNum] = data[i]
                }
                return rd
            },
            reviewDataPush(data){
                let {reviewData} = this
                data = reviewData.concat(data)
                data = this.getReviewObject(data)
                let lastNum = 0
                for(let i in data){
                    let {moveNum} = data[i]
                    if(Number(moveNum) > Number(lastNum)) lastNum = moveNum
                }
                this.reviewData = Object.values(data)
                this.lineChatInit()
                if(lastNum == 0) return lastNum
                return ++lastNum

            },
            async reviewDataGet(opt){
                let {gameId} = this
                opt = opt || {}
                let res = await this.api.reviewData({...opt, gameId})
                if(res){
                    let {reportData = []} = res
                    for(let i in reportData){
                        let {data} = reportData[i]
                        try{
                            data = JSON.parse(data)
                        }catch(e){ data = reportData[i].data}
                        reportData[i].data = data
                    }
                    res.reportData = reportData
                }
                return res
            },
            quit(){
                if(this._common.globalQuit) return this._common.globalQuit()
            },
            terribleGreatChange(val){
                let {great, terrible} = val || {}
                this.greatPoint = great
                this.terriblePoint = terrible
            },
            pointCalculate(val){
                this.pointCalculateData = val
                // this.lineChatInit()
            },
            clearReviewLabel(){
                if(!this._board) return
                this._board.clearReviewLabel()
            },
            async boardLabelSet(){
                if(!this.boardLabelWatch) return this.clearReviewLabel()
                let {firstCoor, isBestPoint, isTerriblePoint} = this
                let data = []
                let {coord} = this.firstCoor
                if(coord){
                    let {x, y} = this._board.board.splitGtp(coord)
                    data.push({
                        x, y, type: 'firstPoint'
                    })
                }
                if(isBestPoint){
                    let {x, y} = this._board.board.splitGtp(coord)
                    data.push({
                        x, y, type: 'bestPoint'
                    })
                }
                if(isTerriblePoint){
                    let {coord} = this.reviewDataNext
                    if(coord){
                        let {x, y} = this._board.board.splitGtp(coord)
                        data.push({
                            x, y, type: 'terriblePoint'
                        })
                    }
                }
                this._board.reviewLabelSet(data)
                
            },
            async lineChatInit(){
                if(!this.$refs.winrateLine) return
                await this._common.nextTick.call(this)
                this.$refs.winrateLine.lineChatInit()
            },
            userInfoLoad(){
                let {userCodeList: list} = this
                for(let i in list){
                    this.userInfoLoadAction(list[i])
                }
            },
            async userInfoLoadAction(user_code){
                let {userList, usercode} = this
                if(userList[user_code] && userList[user_code].userCode) return
                if(!user_code) return
                let res
                if(usercode == user_code) res = this._common.deepCopy(this.$store.state.app)
                else res = await this.api.userAccountOther({user_code})
                if(!res) return
                this.$set(this.userList, user_code, res)
            },
            funHandler(val){
                if(val.isDisabled) return
                return this._common.funHandler.call(this, val)
            },
            async pass(){
                if(this.passDisabled) return false
                this._board.pass()

                // if(!this.playCheck('pass')) return
                // let color = this.$refs['board'].board.now == 1 ? 'B': 'W'
                // await this.$refs['board'].pass()
                // this.funAfterAction('pass')
                // this.setPositionNum(1)
                // return
            },
            async createReview(){
                let state = await this.createReportCheck()
                if(!state) return
                let res = await this.createReviewConfirm()
                if(!res) return
                this.createReviewAction()
            },
            async createReportCheck(){
                // if(!this.noneReviewData) return false
                await this.gameMetaLoad()
                await this._common.nextTick.call(this)
                if(!this.noneReviewData) this.reviewDataInit()
                return this.noneReviewData
            },
            async createReviewAction(){
                // let res = await this.changeUserGameReport()
                // if(!res) return
                this.reviewDataCreate()
            },
            async changeUserGameReport(){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                }
                let res = await this.hisApi.changeUserGameReport(opt)
                return res
            },
            async createReviewConfirm(){
                let data = await this.getGameReportCount()
                if(data === undefined || data === false) return
                let {count, amount} = data || {}
                let message = this._errorCode.gameReview['create report confirm']
                let subMessage = this._errorCode.gameReview['create report sub message']
                message = this._common.urlParse(message, {remindNum: count})
                subMessage = this._common.urlParse(subMessage, {price: this.PARAMS.game_review_price, amount})
                let defineClass = 'game-review-confirm sub'
                if(count > 0) subMessage = undefined, defineClass = 'game-review-confirm'
                
                let res = await this._common.confirmPro.open({
                    message,
                    subMessage,
                    confirmType:'c6',
                    defineClass,
                    buttons: [
                        {title: '取消', label: 1, type: 'cancel'},
                        {title: '开始复盘', label: 0, type: 'confirm'},
                    ]
                })
                res = res || {}
                if(res.label == 0 && count <= 0){
                    let priceRes = await this.changeUserGameReport()
                    if(!priceRes) return
                }
                return res.label == 0
            },
            async getGameReportCount(val){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                    // school_id,
                }
                let res = await this.hisApi.getGameReportCount(opt)
                if(!res) return
                let {dailyRemainCount: count, dailyCount: amount} = res
                // count = 0
                return {count, amount}
            },
        },
        created() {
            
        },
        components: {
            board,
            boardMove,
            moveSwitch,
            terGreMove,
            winrateLine,
            photo
        },
        computed: {
            ...tryPlayConfig.computed,
            ...confirmPlayConfig.computed,
            confirmPlayDisplay(){
                return this.confirmPlaySwitch
            },
            changeLabelDisabled(){
                return this.tryPlaySelected
            },
            boardPlayDisabled(){
                return this.tryPlaySelected ? false : true
            },
            passDisabled(){
                return !this.tryPlaySelected
            },
            
            funListCul(){
                let {funList: list} = this
                return this._common.funListHandler.call(this, list)
            },
            isAIGame(){
                let {gameType} = this.gameMeta
                return this._common.isAIGame(gameType)
            },
            reviewDataCul(){
                return this._common.deepCopy(this.reviewData)
            },
            positionCul(){
                let {realPosition: position, startMoveNum} = this
                return position - startMoveNum
            },
            stepCul(){
                let {realStep: step, startMoveNum} = this
                return step - startMoveNum
            },
            firstCoor(){
                let data = this.reviewDataNow
                let {options = []} = data
                return options[0] || {}
            },
            isBestPoint(){
                let {reviewDataNext: {coord}} = this
                let data = this.reviewDataNow
                return data.level == 0 && coord
            },
            isTerriblePoint(){
                let {terriblePoint, realPosition} = this
                let f = false
                for(let i in terriblePoint){
                    let {moveNum} = terriblePoint[i]
                    if(moveNum == realPosition){
                        f = true
                        break
                    }
                }
                return f
            },
            reviewDataNow(){
                let data = this.reviewData[this.realPosition]
                if(!data) return {}
                data = data.data
                return data
            },
            reviewDataNext(){
                let data = this.reviewData[Number(this.realPosition) + 1]
                if(!data) return {}
                data = data.data
                return data
            },
            // recommendPoind(){},
            finishedPer(){
                let {finished, step} = this
                return ((finished / (Number(step) + 1)) * 100).toFixed(0)
            },
            finishedStyle(){
                return {
                    width: `${this.finishedPer}%`
                }
            },
            startMoveNum(){
                let {startMoveNum} = this.gameMeta
                return startMoveNum || 0
            },
            noneReviewData(){
                let {gameMeta} = this
                return gameMeta.analyzeStatus == this.PARAMS.normalGame
            },
            reviewDataCreating(){
                let {gameMeta} = this
                return gameMeta.analyzeStatus == this.PARAMS.reportingGame
            },
            hasReviewData(){
                let {gameMeta} = this
                return gameMeta.analyzeStatus == this.PARAMS.reportGame
            },
            userRemove(){
                let {userColor} = this
                return userColor == 1 ? this.blackRemove : this.whiteRemove
            },
            AIRemove(){
                let {userColor} = this
                return userColor == -1 ? this.blackRemove : this.whiteRemove
            },
            blackRemove(){
                let {removeStone} = this
                removeStone = removeStone || {}
                let num = removeStone.w || 0
                return num
            },
            whiteRemove(){
                let {removeStone} = this
                removeStone = removeStone || {}
                let num = removeStone.b || 0
                return num
            },
            removeText(){
                let txt = ''
                if(this.isPlay) txt = this.pageText['play remove text']
                if(this.isCapture) txt = this.pageText['capture remove text']
                return txt
            },
            isPlay(){
                let {gameMeta: {gameType} = {}} = this
                // return this._common.getGameType('play') == gameType
                return this._common.gameTypeCheck(gameType) == 'play'
            },  
            isCapture(){
                let {gameMeta: {gameType} = {}} = this
                // return this._common.getGameType('capture') == gameType
                return this._common.gameTypeCheck(gameType) == 'capture'
            },
            usercode(){
                let {app: {usercode} = {}} = this.$store.state
                return usercode
            },
            boardSize(){
                let {gameMeta: {boardSize} = {}} = this
                return boardSize
            },
            userColor(){
                let {gameMeta, usercode} = this
                let {blackUserCode, whiteUserCode, blackLevel, whiteLevel} = gameMeta
                return usercode == blackUserCode ? 1 : -1
            },
            blackInfo(){
                // let {gameMeta} = this
                // let data = {
                //     nickname: gameMeta.pb,
                //     level: gameMeta.blackLevel,
                //     userCode: gameMeta.blackUserCode,
                // }
                // return data

                let {blackUserCode, pb, blackLevel} = this.gameMeta
                let info = this.userList[blackUserCode] || {}
                info = this._common.deepCopy(info)
                info.nickname = pb
                info.level = blackLevel
                if(this.isAIGame && this.usercode != blackUserCode){
                    info.avatarHeadUrl = this.PARAMS.AIPhoto
                }
                return info
            },
            whiteInfo(){
                // let {gameMeta} = this
                // let data = {
                //     nickname: gameMeta.pw,
                //     level: gameMeta.whiteLevel,
                //     userCode: gameMeta.whiteUserCode,
                // }
                // return data


                let {whiteUserCode, pw, whiteLevel} = this.gameMeta
                let info = this.userList[whiteUserCode] || {}
                info = this._common.deepCopy(info)
                info.nickname = pw
                info.level = whiteLevel
                if(this.isAIGame && this.usercode != whiteUserCode){
                    info.avatarHeadUrl = this.PARAMS.AIPhoto
                }
                return info
            },
            leftInfo(){
                let {gameMeta, usercode} = this
                gameMeta = this._common.deepCopy(gameMeta)
                let {blackUserCode, whiteUserCode, blackLevel, whiteLevel} = gameMeta
                gameMeta['blackNickname'] = gameMeta.pb
                gameMeta['whiteNickname'] = gameMeta.pw
                let key = usercode == blackUserCode ? 'black' : 'white'
                let data = {
                    nickname: gameMeta[`${key}Nickname`],
                    level: gameMeta[`${key}Level`],
                    userCode: gameMeta[`${key}UserCode`],
                }
                return data

            },
            rightInfo(){
                let {gameMeta, usercode} = this
                gameMeta = this._common.deepCopy(gameMeta)
                let {blackUserCode, whiteUserCode, blackLevel, whiteLevel} = gameMeta
                gameMeta['blackNickname'] = gameMeta.pb
                gameMeta['whiteNickname'] = gameMeta.pw
                let key = usercode == blackUserCode ? 'white' : 'black'
                let data = {
                    nickname: gameMeta[`${key}Nickname`],
                    level: gameMeta[`${key}Level`],
                    userCode: gameMeta[`${key}UserCode`],
                }
                return data
            },
            boardMinNum(){
                return 0
            },
            situation(){
                let {gameSgfData: {moves}} = this
                return moves || []
            },
            boardLoadWatch(){
                let {boardState, gameId, gameMeta} = this
                gameMeta = gameMeta || {}
                if(!boardState || !Object.keys(gameMeta).length) return false
                return {gameId, gameMeta}
                // return gameId && boardState && Object.keys(gameMeta).length
            },
            loadSgfWatch(){
                let {boardState, situation} = this
                return situation.length && boardState
            },
            reviewDataWatch(){
                let {situation, gameMeta, step} = this
                return gameMeta.id && situation.length && step && situation.length == step
            },
            boardLabelWatch(){
                if(!this.boardState || !this.loadSgfDown || this.tryPlaySelected) return false
                let {realPosition, firstCoor, isBestPoint, isTerriblePoint} = this
                return {position: realPosition, firstCoor, isBestPoint, isTerriblePoint}
            },
            otherUserInfoWatch(){
                let {blackUserCode, whiteUserCode} = this.gameMeta || {}
                let {usercode} = this
                let data = []
                if(blackUserCode != usercode) data.push(blackUserCode)
                if(whiteUserCode != usercode) data.push(whiteUserCode)
                return data
            },
            userCodeList(){
                let {blackUserCode, whiteUserCode} = this.gameMeta || {}
                let data = []
                if(blackUserCode) data.push(blackUserCode)
                if(whiteUserCode) data.push(whiteUserCode)
                return data
            },
        },
        watch: {
            ...tryPlayConfig.watch,
            ...confirmPlayConfig.watch,
            userCodeList: {
                handler(){
                    this.userInfoLoad()
                },
                deep: true,
                immediate: true
            },
            boardLabelWatch: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal)) return
                    this.boardLabelSet()
                },
                deep: true,
                immediate: true
            },
            reviewDataWatch: {
                handler(val, oldVal){
                    if(!val || this.reviewData.length) return
                    this.reviewDataInit()
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(val){
                    this.gameId = this.$route.params.gameId
                },
                deep: true,
                immediate: true
            },
            gameId: {
                handler(val){
                    if(!val) return
                    this.gameMetaLoad()
                },
                deep: true,
                immediate: true
            },
            boardLoadWatch: {
                handler(val, oldVal){
                    if(!val || this._common.isSameObject(val, oldVal)) return
                    console.log('load sgf')
                    this.gameSgfLoad()
                },
                deep: true,
                immediate: true
            },
            loadSgfWatch: {
                handler(val){
                    if(!val) return
                    this.situationLoad()
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.pageDestroy = true
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-game-review-summary{
        width: @designWidth;
        height: @designHeight;
        padding-top: 53px;
        .review-content{
            .review-item.board{
                box-shadow: 1.1px 3px 5px 3px #7991C1;
                border-radius: 13px;
                width: 307px;
                height: 307px;
                .board-body{
                    position: relative;
                    width: 100%;
                    height: calc(100% + 1px);
                }
            }
            .review-item.options{
                padding: 12px;
                .background-img-max(url('@{assetsUrlV2_0}/gameReview/g2.png'));
                margin-left: 10px;
                box-shadow: 1.1px 3px 5px 3px #728FC2;
                border-radius: 13px;
                width: 264px;
                height: 307px;
                .opt-item.g-info{
                    margin-top: -2px;
                    width: 100%;
                    height: 89px;
                    display: flex;
                    position: relative;
                    .info-item{
                        width: 100%;
                        height: 100%;
                    }
                    .info-item.bcg{
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        .bcg-i{
                            position: absolute;
                            top: 0px;
                            height: 115px;
                        }
                        .a{
                            z-index: 1;
                            left: 0px;
                            width: 105px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g23.png'));
                        }
                        .b{
                            z-index: 0;
                            right: 0px;
                            width: 124px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g24.png'));
                        }
                    }
                    .info-item.content{
                        z-index: 2;
                        .con-item{
                            width: 50%;
                            height: 100%;
                            position: absolute;
                            overflow: hidden;
                            padding-top: 6px;
                            .photo-box{
                                // width: 70px;
                                height: calc(100% - 15px);
                                .com-review-photo{
                                    width: 100%;
                                    height: 100%;
                                }
                                .photo-body-bx{
                                    background: none;
                                }
                            }
                            .com-global-ai-photo{
                                height: 100%;
                                img{
                                    height: 100%;
                                    width: auto;
                                }
                            }
                            .com-user-photo{
                                .user-photo-img{
                                    height: 100%;
                                    width: auto;
                                }
                            }
                            .com-global-AI, .com-global-user-avatar{
                                // margin: 6px auto 0px;
                                // width: auto;
                                // height: calc(100% - 18px);
                                width: 38px;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .stone{
                                width: 10px;
                                height: 10px;
                                position: absolute;
                                top: 2px;
                            }
                            .stone.black{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g19.png'));
                            }
                            .stone.white{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g18.png'));
                            }
                            .name-board{
                                position: absolute;
                                bottom: 0px;
                                width: 82px;
                                height: 15px;
                                color: #875C1D;
                            }
                        }
                        .bcg-item{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                        }
                        .left{
                            left: 0px;
                            .left-bcg{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g24.png'));
                                transform: rotateZ(180deg);
                            }
                            .stone{
                                left: 2px;
                            }
                            .name-board{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g25.png'));
                                left: 0px;
                            }
                        }
                        .right{
                            right: 0px;
                            .right-bcg{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g23.png'));
                                transform: rotateZ(180deg);
                            }
                            .stone{
                                right: 2px;
                            }
                            .name-board{
                                .background-img-max(url('@{assetsUrlV2_0}/game/g26.png'));
                                right: 0px;
                            }
                        }
                    }
                }
                .g-remove-box{
                    width: 87px;
                    height: 63px;
                    z-index: 3;
                    .item{
                        position: absolute;
                    }
                    .remove-text{
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g3.png'));
                        .text-it{
                            position: absolute;
                        }
                        .a{
                            top: 6px;
                            width: 58px;
                            height: 14px;
                            color: #875C1D;
                        }
                        .b{
                            left: 0px;
                        }
                        .c{
                            right: 0px;
                        }
                        .b, .c{
                            color: #58423C;
                            width: 50%;
                            height: calc(100% - 26px);
                            bottom: 0px;
                        }
                    }
                    .remove-shadow{
                        z-index: 0;
                        top: 0px;
                        left: 0px;
                        width: 92px;
                        height: 68px;
                        .background-img-max(url('@{assetsUrlV2_0}/gameReview/g3_shadow.png'));
                    }
                }
                .opt-item.chat-box{
                    width: 100%;
                    height: 190px;
                    margin-top: 4px;
                    padding: 6px;
                    position: relative;
                    .background-img-max(url('@{assetsUrlV2_0}/gameReview/g4.png'));
                    .board-move-box{
                        position: absolute;
                        bottom: 6px;
                        left: 0px;
                        width: 100%;
                    }
                    .moves-switch-box{
                        position: absolute;
                        right: 5px;
                        top: 3px;
                    }
                    .winrate-box{
                        width: 100%;
                        height: 77px;
                        position: relative;
                        .win-item.chat{
                            width: 100%;
                            height: calc(100% - 10px);
                            .winrate-head{
                                height: 7px;
                                color: #926744;
                            }
                            .winrate-content{
                                margin-top: 2px;
                                width: 100%;
                                height: calc(100% - 10px);
                            }
                        }
                        .win-item.chat.none-data{
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            .com-confirm-button{
                            }
                            .create-btn-box{
                                transform: scale(0.7);
                            }
                        }
                        .win-item.moves-number{
                            color: #926744;
                            width: 100%;
                            height: 10px;
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                    .game-level-box{
                        width: 100%;
                        height: 83px;
                        padding: 0px 2px;
                        display: flex;
                        justify-content: space-between;
                        // .level-range{
                        //     width: 100%;
                        //     height: 10px;
                        //     padding: 0px 3px;
                        //     .background-img-max(url('@{assetsUrlV2_0}/gameReview/g7.png'));
                        //     .van-slider{
                        //         height: 5px;
                        //         background: #BABABA;
                        //         border: 1px solid #969393;
                        //     }
                        //     .van-slider__bar{
                        //         background: #5BB2FF;
                        //         border: 1px solid #3D86DC;
                        //     }
                        //     .van-slider__button{
                        //         width: 8px;
                        //         height: 8px;
                        //     }
                        // }
                        .q{
                            width: calc(100% - 55px);
                        }
                        .f{
                            width: 40px;
                            margin-left: 5px;
                            .fun-item+ .fun-item{
                                margin-top: 10px;
                            }
                            .fun-item{
                                position: relative;
                                width: 39px;
                                height: 15px;
                                .background-img-max(url('@{assetsUrlV2_0}/gameReview/p1.png'));
                                color: #A35435;
                                .body{
                                    .background-img-max(url('@{assetsUrlV2_0}/gameReview/p1.png'));
                                    .max-size();
                                    position: absolute;
                                    z-index: 2;
                                }
                                .shadow{
                                    content: '';
                                    width: 44px;
                                    height: 20px;
                                    .background-img-max(url('@{assetsUrlV2_0}/gameReview/p1_shadow.png'));
                                    position: absolute;
                                    top: -2px;
                                    left: -3px;
                                    z-index: 1;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
        .review-loading{
            padding: 0px 20px;
            width: 100%;
            color: #58423C;
            .loading-title{
                text-align: center;
                margin-bottom: 5px;
            }
            .loading-slider{
                .press-content {
                    position: relative;
                    padding: 0px 2px;
                    width: 100%;
                    height: 10px;
                    .background-img-max(url('@{assetsUrlV2_0}/loading/c5.png'));
                    .body{
                        transition: width .3s;
                        width: 50%;
                        height: 5px;
                        .background-img-max(url('@{assetsUrlV2_0}/loading/c6.png'));
                    }
                    .perss-text{
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }






        .ex-bcg-box{
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            .stone-item{
                position: absolute;
            }
            .black{
                width: 94px;
                height: 51px;
                bottom: 0px;
                left: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c1.png'));
            }
            .white{
                width: 93px;
                height: 58px;
                top: 0px;
                right: 0px;
                .background-img-max(url('@{assetsUrlV2_0}/exercise/c2.png'));
            }
        }
        .review-page-title{
            width: 159px;
            height: 47px;
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            margin: 0px auto;
            .background-img-max(url('@{assetsUrlV2_0}/gameReview/g1.png'));
            .text-body{
                width: 80px;
                height: 23px;
                margin-top: 15px;
                color: #873F27;
                text-align-last: justify;
            }
            span[class^='text']{
                display: inline;
            }
        }
    }
    
</style>