<template>
    <div class="com-review-photo">

        <user-photo :photo="photo"></user-photo>
    </div>
</template>
<script>
    export default {
        name: '',
        props: {
            info: {
                type: Object,
                default(){ return {} }
            }
        },
        data() {
            return {};
        },
        methods: {},
        created() {},
        components: {},
        computed: {
            photo(){
                let {info: {avatarHeadUrl}} = this
                return avatarHeadUrl
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    
</style>