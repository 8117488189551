

export default {
    name: '',
    props: {
    },
    data() {
        return {
            tryPlay: false,
            tryPlayData: {},
        };
    },
    methods: {
        tryPlayCon(){
            this.tryPlay ? this.tryPlayOff() : this.tryPlayOn()
        },
        tryPlayOn(){
            this.tryPlay = true
            this._board.tryPlayOpen()
        },
        tryPlayOff(){
            this.tryPlay = false
            this._board.tryPlayClose()
            this.cancelPlay()
        },
    },
    created() {},
    components: {},
    computed: {
        tryPlayMoveNum(){
            if(!this.tryPlaySelected) return 0
            let {tryPlayData: {position}, position: now} = this
            return now - position

        },
        tryPlaySelected(){
            return this.tryPlay
        },
        tryPlayText(){
            let {tryPlaySelected} = this
            return tryPlaySelected ? this._errorCode.gameReview['try play off'] : this._errorCode.gameReview['try play on']
        },
        realPosition(){
            let {tryPlayData, position, tryPlaySelected, startMoveNum} = this
            if(tryPlaySelected){
                let {position: tryPos} = tryPlayData || {}
                position = tryPos
            }
            return position
        },
        realStep(){
            let {tryPlayData, step, tryPlaySelected, startMoveNum} = this
            if(tryPlaySelected){
                let {step: stepData} = tryPlayData || {}
                step = stepData.length - 1
            }
            return step
        },
    },
    watch: {},
    mounted() {},
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
    