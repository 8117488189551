import commonApi from '../_EXT_API'

export default class game extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'history'
        let name = 'history'
        opt = {localKey, name}
        super(opt)
    }
    async dataCount(opt){
        opt = opt || {}
        // let type = 2
        let { game_type } = opt
		let options = {
			method: "get",
			data: {
				// type,
                game_type
			},
			...opt
		}
		return this.requestAction('dataCount', options)
	}
	async playCount(opt){
        opt = opt || {}
        // let type = 1
		let options = {
			method: "get",
			data: {
				// type,
			},
			...opt
		}
		return this.requestAction('playCount', options)
	}
    async gameList(opt){
        opt = opt || {}
        // gameType高途:6，高途人机:60，高途人人:68
        let {user_code, page, size, result_type = -1, gameType} = opt
        if(!user_code) return
		let options = {
			method: "get",
			data: {
                page, size, result_type, gameType,
                game_type: gameType
			},
			...opt
		}
		return this.requestAction('gameList', options)
    }
	async getGameReportCount(opt){
		opt = opt || {}
		let {user_code} = opt
		// school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
		opt = {
            ...opt,
            user_code,
        }
		let options = {
			method: "get",
			...opt,
			data: {
				// school_id,
			}
		}
		return this.requestAction('getGameReportCount',options)
	}
	async changeUserGameReport(opt){
		opt = opt || {}
		let {user_code} = opt
		// school_id = school_id || this.PARAMS.school_id
        user_code = user_code || this._appData.getItem('usercode', 'app')
		opt = {
            ...opt,
            user_code,
        }
		let options = {
			method: "post",
			...opt,
			data: {
				// school_id,
			}
		}
		return this.requestAction('changeUserGameReport',options)
	}
}
