<!--  -->
<template>
    <div class='com-global-board-move flex-center'>
        <div class="move-item scale-button" v-for="(v, i) in listCul" :key="i" :class="[v.class, {'disabled': v.disabled}]" @click="move(v)">
            <img :src="`${PARAMS.assetsUrlV2_0}/global/${v.icon}_shadow.png`" class="icon-shadow">
            <img :src="`${PARAMS.assetsUrlV2_0}/global/${v.icon}.png`" class="move-icon">
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            position: Number,
            step: Number,
            min: {
                type: Number,
                default: 0
            },
            max: Number
        },
        data() {
            return {
                list: [
                    {value: 'min', pos: true, icon: 'move1', class: 'a'},
                    {value: -5, pos: false, icon: 'move2', class: 'b'},
                    {value: -1, pos: false, icon: 'move3', class: 'c'},
                    {value: 1, pos: false, icon: 'move4', class: 'd'},
                    {value: 5, pos: false, icon: 'move5', class: 'e'},
                    {value: 'max', pos: true, icon: 'move6', class: 'f'},
                ]
            };
        },
        methods: {
            disabledGet(val){
                let {value} = val || {}
                let {position, step} = this
                if(value == this.min) return position <= this.min
                if(value == step) return position >= step
                if(Number(value) + Number(position) < position) return position <= this.min
                if(Number(value) + Number(position) > position) return position >= step
                return false
            },
            move(val){
                let {value, pos, disabled} = val
                if(disabled) return
                let {position, step} = this
                let rd = null
                if(pos) rd = value
                else {
                    rd = Number(value) + Number(position)
                    if(rd <= this.min) rd = this.min
                    if(rd > step) rd = step
                }
                if(position == rd) return
                this.$emit('change', rd)
            }
        },
        created() {

        },
        components: {
        },
        computed: {
            listCul(){
                let {list, step, position, min, max} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    let {value} = list[i]
                    if(value == 'max') value = max || step
                    if(value == 'min') value = min
                    list[i].value = value
                    list[i].disabled = this.disabledGet(list[i])
                }
                return list
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-global-board-move{
        .move-item+ .move-item{
            margin-left: 19px;
        }
        .move-item{
            height: 14px;
            position: relative;
            .move-icon{
                position: absolute;
                width: 100%;
                height: 100%;
            }
            .icon-shadow{
                position: absolute;
                height: 17px;
                top: -2px;
                left: -2px;
            }
        }
        .move-item.disabled{
            filter: grayscale(1);
        }
        .move-item.a, .move-item.f{
            width: 15px;
            .icon-shadow{
                width: 19px;
            }
        }
        .move-item.b, .move-item.e{
            width: 22px;
            .icon-shadow{
                width: 26px;
            }
        }
        .move-item.c, .move-item.d{
            width: 10px;
            .icon-shadow{
                width: 14px;
            }
        }
        
    }
</style>