<!--  -->
<template>
    <div class='com-game-review-winrate-line' ref="winrateChat">
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            position: Number,
            min: {
                default: 0,
                type: Number
            },
            step: Number,
            reviewData: {
                type: Array,
                default(){
                    return []
                }
            },
            startMoveNum: {
                type: Number,
                default: 0
            },
            appSizeData: {
                type: Object,
                default(){
                    return {}
                }
            },
            userColor: Number,
            terriblePoint: {
                type: Array,
                default(){
                    return []
                }
            },
            greatPoint: {
                type: Array,
                default(){
                    return []
                }
            },
            pointCalculateData: {
                type: Array,
                default(){
                    return []
                }
            },
        },
        data() {
            return {
                winrateEchats: null,
                options: {
                    xAxis: {
                        type: 'category',
                        data: [],
                        axisLabel: {
                            interval: 1,
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#99653D',
                                width: 1
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        interval: 50,
                        data: [],
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#99653D',
                                width: 1
                            }
                        },
                        axisLabel: {
                            formatter: null
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#C4966D',
                                width: 1
                            }
                        }
                    },
                    grid: {  
                        left: '0%',  
                        right: '1%',  
                        bottom: '5%', 
                        top: '20%',
                        containLabel: true  
                    },
                    series: {
                        type: 'line',
                        showAllSymbol: true,
                        symbolSize: 10,
                        itemStyle: {
                            normal: {
                                color: '#5791F0'
                            }
                        },
                        lineStyle: {
                            color: '#83D1F8',
                            width: 1,
                        },
                        data: []
                    }
                    // series: [
                    //     {
                    //         symbol: 'circle',
                    //     }
                    //     // {
                    //     //     data: [150, 230, 224, 218, 135, 147, 260],
                    //     //     type: 'line'
                    //     // }
                    // ]
                }
            };
        },
        methods: {
            pageInit(){
                this.echatsInit()
            },
            async echatsInit(){
                if(this.winrateEchats !== null) return
                let ele = this.$refs['winrateChat']
                this.winrateEchats = this.$echarts.init(ele)
                await this._common.nextTick.call(this)
                this.winrateEchats.getZr().on('click', this.chatClick)
                // this.winrateEchats.getZr().on('mousemove', this.charMouseMove)
            },
            chatClick(params){
                let pointInPixel = [params.offsetX, params.offsetY]
                let pointInGrid = this.winrateEchats.convertFromPixel({ seriesIndex: 0 }, pointInPixel)
                let xIndex = pointInGrid[0]
                let data = this.reviewDataCul[xIndex]
                if(!data) return
                let {moveNum} = data
                this.$emit('positionChange', moveNum)
            },
            charMouseMove(){
            },
            async lineChatInit(){
                let data = this.seriesData || []
                let options = this._common.deepCopy(this.options)
                let type = 'line'
                let {seriesLineSize} = this
                options.series.data = data
                options.series.lineStyle.width = seriesLineSize
                options.xAxis.data = this.xAxisData
                options.xAxis.axisLabel.interval = this.labelShow

                options.yAxis.axisLabel.formatter  = val => {
                    return `${val}%`
                }
                options.yAxis.axisLabel.fontSize = this.labelFontSize
                this.setOptions(options)
            },
            async setOptions(opt){
                this.winrateEchats.setOption(opt, {
                    notMerge: true
                })
            },
            isInPoint(index, list){
                list = list || this.pointCalculateData
                let data = list[index] || {}
                let {terribleMove, greatMove} = data
                return terribleMove || greatMove
            },
            isFlexion(index, list, userColor){
                list = list || this.reviewDataCul
                let nextIndex = Number(index) + 1
                let lastIndex = Number(index) - 1
                let nextData = list[nextIndex]
                let lastData = list[lastIndex]
                let data = list[index] || {}
                if(!nextData || !lastData) return false
                let {data: {value} = {}, moveNum} = data
                let {data: {value: nextValue} = {}, moveNum: nextMoveNum} = nextData
                let {data: {value: lastValue} = {}, moveNum: lastMoveNum} = lastData
                value = moveNum % 2 == 0 ? value : 1 - value
                nextValue = nextMoveNum % 2 == 0 ? nextValue : 1 - nextValue
                lastValue = lastMoveNum % 2 == 0 ? lastValue : 1 - lastValue
                let color = moveNum % 2 == 0 ? -1 : 1
                // if(color != userColor) return false
                let delta = 0
                if(value < nextValue && value < lastValue) delta = nextValue - value
                if(value > nextValue && value > lastValue) delta = value - nextValue
                return delta > 0.05
            },
            pointFrontCheck(index, list, splitVal, lastLabel){
                let sp = splitVal, nextPoint = null, lastPoint = null
                let i = index - 1
                // while(i >= 0){
                //     if(list[i]){
                //         lastPoint = i
                //         break
                //     }
                //     i--
                // }
                i = Number(index) + 1
                while(i <= list.length - 1){
                    if(list[i]){
                        nextPoint = i
                        break
                    }
                    i++
                }
                let delta = null
                if(lastLabel !== null && (index - lastLabel) < sp){
                    return false
                }
                if(nextPoint !== null && nextPoint - index < sp){
                    return false
                }
                return true
                // if(delta !== null) return delta >= sp
                
                // return true
            },
            labelShow(index, opt){
                // list, point, userColor, necePoint
                opt = opt || {}
                let {reviewData: list = this.reviewDataCul, point = this.pointCalculateData, userColor = this.userColor, necePoint = this.necePoint, lastLabel = 0} = opt
                let length = list.length
                let sp = this.splitVal
                if(Number(index) == 0) return '' + index
                // if(this.isInPoint(val, point)) return val
                // if(this.isFlexion(val, list, userColor)) return val
                if(necePoint[index]) return index
                if(Number(index) == length - 1) return index
                if(!this.pointFrontCheck(index, necePoint, sp, lastLabel)) return false
                // if(index % sp != 0) return false
                // if(length - index < sp) return false
                return index
                // return false
            },
            necePointGet(index, opt){
                opt = opt || {}
                let {reviewData: list, point, userColor} = opt
                if(this.isInPoint(index, point)) return true
                if(this.isFlexion(index, list, userColor)) return true
                return false
            },
            eventSet(){
                this._common.eventSet('onresize', this.resize)
            },
            eventRemove(){
                this._common.eventRemove('onresize')
            },
            resize(){
                if(this.winrateEchats) this.winrateEchats.resize()
            },
        },
        created() {
            this.eventSet()
        },
        components: {
        },
        computed: {
            necePoint(){
                let {reviewDataCul: reviewData, pointCalculateData: point, userColor} = this
                let list = []
                for(let i in reviewData){
                    let val = this.necePointGet(i, {reviewData, point, userColor})
                    list[i] = val ? true : false
                }
                return list
            },
            appPer(){
                let {appSizeData: {fontSize}} = this
                let {designWidth} = this.PARAMS
                let basicFontSize = designWidth / 10
                let data = fontSize / basicFontSize
                return data
            },
            seriesLineSize(){
                let defaultSize = 1, {appPer} = this
                let data = (defaultSize * appPer).toFixed(0)
                return data
            },
            labelFontSize(){
                let defaultSize = 6, {appPer} = this
                let data = (defaultSize * appPer).toFixed(0)
                return data
            },
            seriesLabelSize(){
                let defaultSize = 5, {appPer} = this
                let data = (defaultSize * appPer).toFixed(0)
                return data
            },
            splitVal(){
                let length = this.reviewData.length
                let splitV = ((length - 1) / 10).toFixed(0)
                if(splitV >= 10){
                    let splitV1 = splitV % 10
                    let splitV2 = parseInt(splitV / 10) * 10
                    splitV1 = (splitV1 / 10).toFixed(0) * 10
                    splitV = splitV1 + splitV2
                }
                return splitV
            },
            yAxisData(){
                return [
                    '0%',
                    '50%',
                    '100%',
                ]
            },
            xAxisData(){
                let {reviewDataCul: list} = this
                let rd = []
                for(let i in list){
                    rd.push(i)
                }
                return  rd
            },
            reviewDataCul(){
                let {reviewData: list, startMoveNum: num} = this
                list = this._common.deepCopy(list)
                list = list.splice(num, list.length - num)
                return list
            },
            seriesData(){
                let {reviewDataCul: list, pointCalculateData: point, necePoint, userColor} = this
                list = this._common.deepCopy(list)
                let rd = [], color = 1, lastLabel = 0
                for(let i in list){
                    let {data: {value}, moveNum} = list[i]
                    value = color == 1 ? value : 1 - value
                    value = parseFloat((value * 100).toFixed(1))
                    let val = this.labelShow(i, {reviewData: list, point, userColor, necePoint, lastLabel}) === false ? false : true
                    if(val) lastLabel = i
                    let {seriesLabelSize: symbolSize} = this
                    let itemStyle
                    if(this.position == moveNum){
                        itemStyle = {
                            normal: {
                                color: '#926744'
                            }
                        }
                    }
                    // itemStyle = {
                    //     normal: {
                    //         color(){
                    //         }
                    //     }
                    // }
                    
                    rd.push({
                        value,
                        symbol: val ? 'circle' : 'none',
                        itemStyle,
                        showSymbol: val,
                        symbolSize
                    })
                    color = -color
                }
                return rd
            },
            initWatch(){
                let {seriesData: data, winrateEchats: vender} = this
                if(!vender || !data || !data.length) return false
                return true
            }
        },
        watch: {
            // pointCalculateData: {
            //     handler(){
            //         this.lineChatInit()
            //     },
            //     deep: true,
            //     immediate: true
            // },
            // initWatch: {
            //     handler(val){
            //         if(!val) return
            //         this.lineChatInit()
            //     },
            //     deep: true,
            //     immediate: true
            // },
        },
        mounted() {
            this.pageInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventRemove()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-game-review-winrate-line{
        width: 100%;
        height: 100%;
    }
</style>